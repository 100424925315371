@media screen and (min-width: 767px) {
    #checkout-cart .cart {
        display: none;
    }
}
@media screen and (max-width: 1665px) {
    .container-custom {
        padding: 2em 3em;
    }
    .featured--wrap, .home--tabs, .tabs_row {
        padding: 4em;
    }
    .swiper-carousel {
        .caption span {
            font-size: 17px;
        }
    }
}
@media screen and (max-width: 1499px) {
    .container-custom {
        padding: 2em 15px;
    }
    .footer {
        padding: 5em 4em 10px;
    }
    .product-layout {
        .product-thumb {
            padding: 0;
            .details {
                .price {
                    .price {
                        font-size: 25px;
                    }
                }
                .caption {
                    h4 {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1399px) {
    .header {
        .row--bottom {
            padding: 2em 3em;
        }
        .product_counter {
            right: 5em;
        }
    }
    .swiper-carousel  {
        .caption {
            p {
                font-size: 25px;
            }
            span {
                font-size: 16px;
            }
        }
    }
}
@media screen and (max-width: 1255px) {
    .header {
        .row--bottom {
            padding: 2em 15px;
        }
        .product_counter {
            right: 2em;
        }
    }
}
@media screen and (max-width: 1245px) {
    .header {
        .row--bottom {
            .input-group {
                padding: 6px;
            }
        }
    }
}
@media screen and (max-width: 1199px) { 
    .footer {
        padding: 2em 1em 10px;
        font-size: 15px;
        .h5, h5 {
            font-size: 18px;
        }
    }
    .product_details_col {
        .product-buttons-wrap {
            justify-content: flex-start;       
        }
        #button-cart {
            margin-left: 1em;
        }
    }
    .featured--wrap, .home--tabs, .tabs_row {
        padding: 4em 1em;
    }
    .header {
        .row--bottom {
            justify-content: space-between;
            .input-group {
                justify-content: center;
            }
        }
    }
    .col-menu {
        display: inline-flex;
        .menu {
            &.icon {
                color: $primaryColor;
                position: relative;
                top: 6px;
                margin-right: 12px;
                margin-top: 0;
                width: 24px;
                height: 2px;
                background-color: white;

                &:before {
                    content: '';
                    position: absolute;
                    top: -7px;
                    left: 0;
                    width: 24px;
                    height: 2px;
                    background-color: white;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 7px;
                    left: 0;
                    width: 24px;
                    height: 2px;
                    background-color: white;
                }  
            }
        }
    }
    .hc-offcanvas-nav {
       
            z-index: 9999999;
        
        .nav-wrapper, ul {
            background-color: #2771e0;            
        }
        .nav-item-link {
            color: #fff; 
        }
        .nav-close-button span {
            &::before {
                margin-left: -6px;
            }
        }
        .nav-content {
            &> h2, h3, h4, h5, h6 {
                color: #fff;
            }
            >.nav-close:first-child a {
                background-color: #2771e0; 
            }
            .nav-title-btns {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                column-gap: 5%;
                row-gap: 20px;

                .btn {
                    border-radius: 0;
                    border: 1px solid black;
                    font-size: 21px;

                    &:active {
                        background-color: black;
                        color: white;
                    }
                }
            }
        }
        .nav-back span::before, .nav-next span::before {
            border-color: #fff;
        }
    }
    .hc-offcanvas-nav .nav-wrapper>.nav-content>ul:first-of-type>li:first-child:not(.nav-back):not(.nav-close)>.nav-item-wrapper>.nav-item-link, .hc-offcanvas-nav a.nav-next, .hc-offcanvas-nav .nav-back a, .hc-offcanvas-nav .nav-item-link, .hc-offcanvas-nav li.nav-close a {
        border-color: #2771e0;
    }
    .hc-offcanvas-nav .nav-back a, .hc-offcanvas-nav .nav-title+.nav-close a.has-label, .hc-offcanvas-nav li.nav-close a {
        background-color: #2771e0;
    }
    .hc-offcanvas-nav .nav-back a, .hc-offcanvas-nav .nav-item-link, .hc-offcanvas-nav li.nav-close a {
        font-size: 16px;
    }
    .hc-nav-trigger {
        top: 3px;
        position: relative;
        display: inline-flex;
    }
}
@media screen and (max-width: 1099px) {
    .footer {
        .newsletter-row {
            .input_wrapper .btn-primary {
                margin-left: 0;
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .header {
        .row--bottom {
            padding: 2em 5px;
        }
        .product_counter {
            right: 1em;
        }
    }
    .product_details_col .desc {
        display: none;
    }
    .tabs_row {
        margin-top: 0;
    }
    #slideshow0, .slider0 {
        display: none;
    }
    #slideshow1, .slider1 {
        display: block;
    }
    .featured--wrap, .home--tabs, .tabs_row {
        padding: 2em 1em;
    }
    #featured-tab, #home-tab{
        justify-content: center;
    }
    .main-swiper .swiper-wrapper .swiper-slide .caption {
        display: none;
    }
}
@media screen and (max-width: 845px) {
    .module {
        margin-top: 10rem;
    }
    #product-category, #product-manufacturer, #product-search, #product-special {
        .alert {
            width: 100%;
        }
    }
}
@media screen and (max-width: 767px) {
    .header {
        position: relative;
        .row--bottom {
            padding: 1em 5px;
        }
        .product_counter {
            right: 1.5em;
            top: 7.5em;
        }
    }
    .product_details_col .qty label {
        display: none;
    }
    .tabs_row {
        padding: 2em 5px;
        .tab-content .tab-pane {
            padding: 45px 12px;
        }
    }
    .module {
        margin-top: 0; 
    }
    .footer {
        text-align: center;
        .bottom-row:before {
            margin-top: -14em;
        }
        .h5, h5 {
            margin-bottom: 1em;
        }
        .newsletter-row .input_wrapper .form-control {
            max-width: 100%;
        }
    }
}
@media screen and (max-width: 540px) {
    .header {
        .product_counter {
            right: 2em;
            top: 13.5em;
        }
    }
}

@media screen and (max-width:576px){
    .cart_product_row{
        .btn-remove{
            padding:5px;
        } 
       
    }
    .col-menu .menu.icon{
        top:-6px;
    }
    .row--bottom{
        display:flex;
        flex-wrap:wrap;
        .left-part-mobile{
            display:flex;
            flex-wrap:wrap;
            flex:0 0 auto;
            width:60%;
            .mobile-menu-container{
                flex:0 0 auto;
                width:20%;
            }
            .logo-container{
                flex:0 0 auto;
                width:80%;
            }
        }

        .right-part-mobile{
            flex:0 0 auto;
            width:40%;
        }
    }

    .mobile-search{
        margin-top:20px;
    }

    .header{
        .product_counter{
            top: -22px;
            right: -6px;
            height: 20px;
            width: 20px;
            font-size: 12px;
        }
    }

    .mobile-phone{
        top: 4px;
        position: relative;
    }

    #dropdown-account{
        &.login-aside{
            &.show{
                width:100vw;
            }
        }

    }

    #dropdown-cart{
        &.cart-aside{
            &.show{
                width:100vw;
            }
        }

    }


    ul#home-tab,#featured-tab {
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-box-pack: unset;
        -ms-flex-pack: unset;
        justify-content: unset;
    


        li{
            flex: 0 0 auto!important;
            margin-right:10px;
        }
    }

    .alert{
        position:relative;
        button{
            position:absolute;
            top:10px;
            right:10px;
        }
    }

    .alert-success{
        top:30vh;
    }

    .product_image_col{
        .owl-item{
            img{
                padding:unset;
            }
        }
    }

    .related-carousel{
        .owl-prev{
            top:50%;
            left:1%;
    
        }
    
        .owl-next{
            top:50%;
            right:1%;
        }
    }

    .product-buttons-wrap{
       flex-direction:column;
       justify-content:flex-start;
       align-items:flex-start!important;
    }

    .product-buttons-wrap ul{
        width:100%;
    }

    .product_details_col .qty{
        max-width:40%;

        margin-bottom:20px;

    }
    .product_details_col{
        .button-group{
            width:100%;
            flex:0 0 auto;
        }
    }

    .product_details_col #button-cart{
        margin-left:0;
    }



}

@media screen and (min-width:768px){

    .mobile-search,.mobile-phone{
        display:none;
    }

}

@media screen and (min-width:576px) and (max-width:767px){

    #mobile-menu-trigger {

        top: -5px;
    }
    .right-part-mobile{
        .nav-fill .nav-item{
            flex:0 0 auto;
        }
        ul{
            justify-content:flex-end;
           
               
                .account,.wishlist-header{
                    margin-right:30px;
                }
                     
        }

    }
    .mobile-search{
        margin-top:10px;
        width:90%;
    }
    .mobile-phone{
        width:10%;
    }

    .header{
        .row--bottom{
            .input-group{
                max-width:unset;
            }
        }
    }
    .header{
        .product_counter{
            top: -22px;
            right: -14px;
            height: 20px;
            width: 20px;
            font-size: 12px;
        }
    }

    .mobile-phone{
        top: 4px;
        position: relative;
    }

    #dropdown-account{
        &.login-aside{
            &.show{
                width:80vw;
            }
        }

    }

    #dropdown-cart{
        &.cart-aside{
            &.show{
                width:80vw;
            }
        }

    }



    ul#home-tab,#featured-tab {
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-box-pack: unset;
        -ms-flex-pack: unset;
        justify-content: unset;
    
        li{
            flex: 0 0 auto!important;
            margin-right:10px;
        }
    }

    .alert{
        position:relative;
        button{
            position:absolute;
            top:10px;
            right:10px;
        }
    }

    .related-carousel{
        .owl-prev{
            top:50%;
            left:1%;
    
        }
    
        .owl-next{
            top:50%;
            right:1%;
        }
    }

    .product-buttons-wrap ul{
        width:100%;
    }
}

@media screen and (min-width:768px) and (max-width:992px){
    .left-part-mobile{
        width:40%;
    }
    .search-desktop{
        width:40%;
    }
    .right-part-mobile{
        width:20%;
    }
    #mobile-menu-trigger {

        top: -7px;
    }

    .subfooter{

        .left-part,.center-part,.right-part{
            width:100%;
            text-align:center!important;
            margin-top:10px;

        }
    }
    .footer .bottom-row:before{
        margin-top:-8em;
    }

    .header{
        .product_counter{
            top: -22px;
            right: -14px;
            height: 20px;
            width: 20px;
            font-size: 12px;
        }
    }

    .mobile-phone{
        top: 4px;
        position: relative;
    }

    #dropdown-account{
        &.login-aside{
            &.show{
                width:60vw;
            }
        }

    }

    #dropdown-cart{
        &.cart-aside{
            &.show{
                width:60vw;
            }
        }

    }



    

    ul#home-tab,#featured-tab {
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-box-pack: unset;
        -ms-flex-pack: unset;
        justify-content: unset;
    


        li{
            flex: 0 0 auto!important;
            margin-right:10px;
        }
    }

    .related-carousel{
        .owl-prev{
            top:50%;
            left:1%;
    
        }
    
        .owl-next{
            top:50%;
            right:1%;
        }
    }

    .product-buttons-wrap ul{
        width:100%;
    }
}

@media screen and (min-width:993px) and (max-width:1200px){

    #mobile-menu-trigger {

        top: -7px;
    }

    .header{
        .product_counter{
            top: -22px;
            right: -14px;
            height: 20px;
            width: 20px;
            font-size: 12px;
        }
    }

    .mobile-phone{
        top: 4px;
        position: relative;
    }

    #dropdown-account{
        &.login-aside{
            &.show{
                width:60vw;
            }
        }

    }

    #dropdown-cart{
        &.cart-aside{
            &.show{
                width:60vw;
            }
        }

    }


    ul#home-tab,#featured-tab {
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-box-pack: unset;
        -ms-flex-pack: unset;
        justify-content: unset;
    


        li{
            flex: 0 0 auto!important;
            margin-right:10px;
        }
    }

    .related-carousel{
        .owl-prev{
            top:50%;
            left:1%;
    
        }
    
        .owl-next{
            top:50%;
            right:1%;
        }
    }
}

@media screen and (min-width:1300px) and (max-width:1400px){
    #nav-menu{
        &>ul{
            flex:0 0 auto;
            width:25%;
        }

        .tab-content{
            flex:0 0 auto;
            width:75%;            
        }
    }
} 

@media screen and (min-width:1200px) and (max-width:1400px){
.subfooter{
    .center-part{
        ul{
            li{
                &:not(:last-child){
                    margin-right:0!important;
                }
            }
        }
    }
}

.header{
    .product_counter{
        top: -22px;
        right: -14px;
        height: 20px;
        width: 20px;
        font-size: 12px;
    }
}

.mobile-phone{
    top: 4px;
    position: relative;
}

#dropdown-account{
    &.login-aside{
        &.show{
            width:40vw;
        }
    }

}

#dropdown-cart{
    &.cart-aside{
        &.show{
            width:40vw;
        }
    }

}


.related-carousel{
    .owl-prev{
        top:50%;
        left:1%;

    }

    .owl-next{
        top:50%;
        right:1%;
    }
}


.product-buttons-wrap{
    justify-content:unset!important;
    ul{
        flex:0 0 auto!important;
        width:30%;
    }
    .qty{
        flex:0 0 auto!important;
        margin-right:10px;
        max-width:28%;
        
    }
}





}


@media screen and (min-width:1200px) and (max-width:1300px){
    .product-buttons-wrap ul{
        width:100%;
    }

    .product_details_col .product-buttons-wrap{
        flex-wrap:wrap;
    }
    .product_details_col .availability{
        margin-top:20px;
    }

}


@media screen and (max-width:1600px) and (min-width:1500px){
    .product-buttons-wrap{
        justify-content:unset!important;
        ul{
            flex:0 0 auto!important;
            width:33%;
        }
        .qty{
            flex:0 0 auto!important;
            margin-right:10px;
            max-width:30%;
        }
    }

    .product_details_col #button-cart {
        padding: 12px 38px;
    }
}

@media screen and (max-width:1700px) and (min-width:1601px){
    .product-buttons-wrap{
        justify-content:unset!important;
        ul{
            flex:0 0 auto!important;
            width:36%;
        }
        .qty{
            flex:0 0 auto!important;
            margin-right:10px;
            max-width:29%;
        }
    }

    .product_details_col #button-cart {
        padding: 12px 38px;
    }
}

@media screen and (max-width:1890px) and (min-width:1701px){
    .product-buttons-wrap{
        justify-content:unset!important;
        ul{
            flex:0 0 auto!important;
            width:31%;
        }
        .qty{
            flex:0 0 auto!important;
            margin-right:10px;
            max-width:29%;
        }
    }


}

@media screen  and (min-width:1891px){
    .product-buttons-wrap{
        justify-content:unset!important;
        ul{
            flex:0 0 auto!important;
            width:31%;
        }
        .qty{
            flex:0 0 auto!important;
            margin-right:10px;
            max-width:29%;
        }
    }


}

@media screen and (max-width:800px){
    .header-info-desktop{
        display:none;
    }
}

@media screen and (min-width:801px){
    .header-info-mobile{
        display:none;
    }
}

@media screen and (min-width:1200px){
    #tab-specification{
        padding-left:3vw;
        padding-right:3vw;
        table.table.product-attributes {
            width: 50%;
            margin: 0 auto;
        }
    }


}

@media screen and (max-width:992px){
    .mfilter-box{
        padding-right:unset!important;
    }
    .mfilter-light-theme .mfilter-heading{
        font-size:18px!important;
    }
    .mfilter-filter-item {
      
        padding-bottom: 5px!important;
        margin-top: 5px!important;
    }

    .mfilter-free-button{
        top:150px!important;
    }

    .info-wrapper{

        ul {
            flex-wrap: nowrap;
            overflow-y: hidden;
            overflow-x: auto;
            height: 60px;
            padding-left:10px;
            margin-bottom: 0;
            list-style: none;
    
            -moz-column-gap: unset;
    
    
            li{
                flex: 0 0 auto!important;
                margin-right:10px;
            }
        }

    }
}

@media screen and (min-width:1600px){
        .right-part-mobile{
            .header-icons-container{
                justify-content: flex-end;
                &>li{
                    flex:0 0 auto!important;
                    width:25%;
                }
            }
        }
}

@media screen and (max-width:992px){
    .alert-success{
        width:100vw!important;
    }
}

@media screen and (min-width:1200px){
    .live-search{
        .product-add-cart{
            padding:0;

            a{

                padding:2px;
                width:30px;
                i{
                    font-size:12px;
                }
            }
        }
    }

    .live-search ul li .product-name{
        width:40%!important;
    }
}

.accordion-button:focus{
    border:none;
    box-shadow:none;
}

.nav-item.account,#cartTrigger{
    cursor: pointer;
}