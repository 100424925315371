body {
    color: #777;
	font: {
        family: $roboto;
        size: 16px;
    }	
}
h1, h2, h3, h4, h5, h6 {
	color: #444;
}
/* default font size */
.fa {
    font-size: 18px;
}
/* Override the bootstrap defaults */
h1 {
	font-size: 33px;
}
h2 {
	font-size: 27px;
}
h3 {
	font-size: 21px;
}
h4 {
	font-size: 15px;
}
h5 {
	font-size: 12px;
}
h6 {
	font-size: 10.2px;
}
a {
	color: $primaryColor;
}
a:hover {
    text-decoration: none;
    color: $black;
}
legend {
	font-size: 18px;
	padding: 7px 0;
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

button {
    outline: none!important;
}
.buttons {
    margin-top: 2em;
}
#logo {
    margin: 0 0 10px 0;
}
#main {
    margin-top: 226px;
    background-color: #ececec;
}
.owl-item {
	.product-layout {
		.product-thumb {
			img {
				display: inline-block;
			}
		}
	}
}
#mp-menu {
    display: none;

    &.mm-menu_opened {
        display: block;
        z-index: 6;
    }
}

.mm-wrapper_opened {
    .mm-menu_fx-menu-slide {
        -webkit-transform: translate(-100%, 0);
        -ms-transform: translate(-100%, 0);
        transform: translate(-100%, 0);
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

.mm-wrapper_opening {
    .mm-menu_fx-menu-slide {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.mm-btn {
    &.mm-btn_prev {
        &:before {
            border-color: #000;
            width: 12px;
            height: 12px;
        }
        &.mm-navbar__btn {
            left: 0;
        }
    }
}

.required .col-form-label:before {
    content: '* ';
    color: #F00;
    font-family: $robotoB;
}
.btn-default {
    border: 2px solid $primaryColor;
    background-color: transparent;
    font-weight: 600;
    color: $primaryColor;
    &:hover {
        background-color: $primaryColor;
        color: #fff;
    }
}
.btn-primary {
    font-weight: 600;
    border: 2px solid $primaryColor;
    color: #fff;
    &:hover {
        background-color: transparent;
        color: $primaryColor;
    }
}
.btn-remove {
    color: #fff;
    background-color: #cc1919;
    padding: 5px 10px;
    &:hover {
        opacity: 0.8;
        background-color: #cc1919;
    }
}
.form-control {
    color: #7a7a7a;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid $primaryColor;
    margin-bottom: 12px;

    &:focus {
        box-shadow: none;
        border-bottom: 2px solid #777;
    }
}
.text-danger {
    color: #cc1919;
}
.input-group {
    .btn {
        z-index: auto;
    }
}
.alert {
    background-color: #eee;
    border-radius: 0;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
}
.dropdown-menu {
    border-radius: 0;
    padding: 18px;
    ul {
        box-shadow: none;
    }
}
#product-category,
#product-special,
#product-manufacturer,
#product-search {
    .alert {
        position: absolute;
        z-index: 1;
        width: 85%;
        right: 0;
        left: 0;
        text-align: center;
        margin: 0 auto;
    }
}