.footer {
    background-color: $primaryColor;
    padding: 5em 9em 10px;
    font-size: 17px;
    color: $white;
    line-height: 2;
    h5 {
        margin-bottom: 2em;
        font-size: 22px;
        font-family: $robotoB;
        color: #fff;
    }
    a {
        color: #fff;
        &:hover {
            color: #fff;
        }
    }
    .newsletter-row {        
        .terms {
            font-size: 14px;
            margin-top: 1em;
        }
        .content_box {
            margin: 2em auto;
        }
        .input_wrapper {
            .form-control {
                background: $secondaryColor;
                border: 0;
                color: $white;
                max-width: 225px;
                height: 45px;
                border-radius: 45px;
                &::-webkit-input-placeholder {
                    color: $white;
                }
                &::-moz-placeholder {
                    color: $white;
                }
                &:-ms-input-placeholder {
                    color: $white;
                }
                &:-moz-placeholder {
                    color: $white;
                }
            }
            .btn-primary {
                background: $white;
                font-size: 16px;
                font-family: $robotoM;
                color: $thirdColor;
                border-radius: 20px;
                min-width: 140px;
                height: 45px;
                border-radius: 45px;
                margin-left: -40px;
                border: 0;
            }
        }
    }
    .bottom-row {
        &:before {
            @extend %content;
            background-color: rgba(255,255,255,.4);
            height: 2px;
            width: 100%;
            left: 0;
            margin-top: -5em;
        }
    }
}

.footer-tax{
    margin-top:20px;
    padding-left:0;
    list-style-type:  none;
    li{
        color:#fff;
        span{
            font-weight:bold;
        }
    }

}