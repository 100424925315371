@font-face {
    font-family: $roboto;
    src: url('../fonts/Roboto-Regular.ttf');
}
@font-face {
    font-family: $robotoB;
    src: url('../fonts/Roboto-Bold.ttf');
}
@font-face {
    font-family: $robotoM;
    src: url('../fonts/Roboto-Medium.ttf');
}
@font-face {
    font-family: $robotoL;
    src: url('../fonts/Roboto-Light.ttf');
}

%font-awesome {
    font: {
        family: "Font Awesome 5 Free";
        weight: 600;
        size: 15px;
    }
}
