@import "variables";

@import "../node_modules/bootstrap/scss/bootstrap";

@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../node_modules/@fortawesome/fontawesome-free/scss/regular";

@import "../node_modules/owl.carousel/src/scss/owl.carousel";
@import "../node_modules/sweet-dropdown/dist/min/jquery.sweet-dropdown.min.css";

@import "../node_modules/hc-offcanvas-nav/src/scss/core";
@import "../node_modules/hc-offcanvas-nav/src/scss/toggle";
@import "../node_modules/hc-offcanvas-nav/src/scss/theme-default";

@import "mixins";
@import "fonts";

@import "./components/theme";
@import "./components/header";
@import "./components/module";
@import "./components/cart";
@import "./components/footer";

.breadcrumb {
    border: 0;
    background: none;
    margin-bottom: 2em;
    color: #7A7A7A;
    margin-top: 1em;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    height: 60px;

    margin-bottom: 0;
    list-style: none;

    -moz-column-gap: unset;



    li {
        padding: 0 10px 0 0;
        flex:0 0 auto;

        &:last-of-type {
            &:after {
                content: "";
            }
        }

        &:after {
            content: ">";
            top: 0px;
            padding-left: 1em;
            right: -12px;
            width: 20px;
            height: 20px;
            border: 0px;
            transform: rotate(0deg);
        }

        a {
            color: #7A7A7A;
        }
    }
}

.sort_label {
    margin-top: 5px;
}
.product_image_col {
    text-align: center;

    .thumbnails, .img-thumbnail {
        border: 1px solid #f0f0f0;
        border-color: transparent;
    }

    .free-shipping {
        position: absolute;
        z-index: 2;
        right: 15px;
    }

    .owl-carousel {
        border-radius: 30px;
        overflow: hidden;
        background: #fff;
    }
    .owl-item {
        img {
            max-width: 500px;
            padding: 30px;
            margin: auto;
        }
    }

    .owl-thumbs {
        margin-top: 20px;
        display: inline-flex;

        .owl-thumb-item {
            background: #fff;
          
            margin: 0 5px 0 0;
            border-radius: 30px;
            overflow: hidden;

            &:last-of-type {
                margin-right: 0px;
            }

            img {
              
                margin: auto;
                padding:10px;
            }

            &.active {
                border: 1px solid $primaryColor;
            }
        }
    }
}

.product_details_col {
    line-height: 1.8;
    .product_title {
        color: #404040;
        font-size: 22px;
        margin-bottom: 1em;
    }

    .brand, .model {
        color: #949494;
        font-size: 15px;
        a {
            color: #949494;
        }
    }

    .prices {
        .price {
            font-size: 29px;
            font-family: $robotoB;
            color: #404040;
        }
        .price-old {
            color: #404040;
            font-family: $robotoM;
            font-size: 20px;
            text-decoration: line-through #D97400;
        }
    }

    .tabletop {
        margin-top: 16px;
        td {
            width: 45%;
        }
    }   

    .qty {
        flex: 1;
        width: 100%;
        max-width: 33%;
        display: flex;
        padding: 0 15px;
        align-items: center;
        border-radius: 26px;
        border: 2px solid #949494;
        color: #949494;

        label {
            margin-bottom: 0px;
            font-size: 0.9rem;
        }

        .input-group-btn-vertical {
            display: grid;
            padding: 0 0 0 18px;
        }

        .input-group.bootstrap-touchspin.bootstrap-touchspin-injected {
            border: 0;
            display: flex;
            justify-content: center;
        }

        .input-group-prepend {
            margin-right: 10px;
        }

        .input-group-append {
            margin-left: 10px;
        }

        .input-group {
            .form-control[name^="quantity"] {
                border: 0;
                outline: none;
                max-width: 52%;
                margin: 0;
                border-right: 2px solid #949494;
            }
        }

        .bootstrap-touchspin-up,
        .bootstrap-touchspin-down {            
            border: 0;
            border-radius: 0;
            background: transparent;
            font-size: 17px;
            color: #404040;
            padding: 0;
            box-shadow: none;
            text-shadow: none;
        }
    }

    .availability {
        color: #9A9A9A;
        line-height: 1.8;
        font-weight: 600;           
        .instock {
            color: #128405;
        }
        .lowstock {
            color: #d32b25;
        }
        .outofstock {
            color: #b41b14;
        }
    }

    .desc {
        color: #404040;
        .desc-title {
            font-size: 15px;
            font-family: $robotoM;
        }
        .mini-desc {
            overflow: hidden;
            max-height: 100px;
        }
    }

    #button-cart {
        padding: 12px 52px;
        font-size: 17px;
        font-family: $robotoM;
        border: 2px solid $primaryColor;
        background-color: transparent;
        line-height: 26px;
        box-shadow: none;
        text-shadow: none;
        outline: none;
        display: inline-block;
        overflow: hidden;
        color: $primaryColor;
        border-radius: 30px;

        &:hover {
            color: #fff;
            background: $primaryColor;
            border: 2px solid $primaryColor;
        }
    }

    .btn-wish {
        padding: 0;
        margin-left: 1em;
        .far {
            padding: 13px;
        }
        &:hover {
            
            .far {
                background-color: $thirdColor;
                color: #fff;
            }
        }
    }

    .product-buttons-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .add_to_cart_wrapper {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-around;

            ul {
                margin-bottom: 0;

                .price-new {
                    color: #404040;
                    font-size: 1.35rem;
                    font-family: $robotoM;

                    .price-old {
                        font-size: 1rem;
                    }
                }
            }
        }

        .action {
            min-width: 80px;

            .wishlist {
                width: 40px;
                height: 40px;
                border: 1px solid;
                border-radius: 40px;
                color: $primaryColor;
                background: #fff;
                font-size: 1rem;
                padding: 0px;
                line-height: 40px;
                text-shadow: none;
                box-shadow: none;
                outline: none;
                transform: scale(1);

                &:hover {
                    transform: scale(1.2);
                }
            }
        }

        .divider_v {
            height: 80px;
            margin: 0px 20px;
        }
    }
}

.tabs_row {
    margin-top: 5rem;

    .nav-tabs {
        border: 0px;
        margin-bottom: 1rem;

        li {
            overflow: hidden;
            padding: 0px;
            border-radius: 30px;
            min-width: 260px;
            border: 0;
            font-size: 1rem;

            a {
                display: block;
                width: 100%;
                padding: 10px 20px;
                font-family: $robotoM;
                color: #444;
                border-radius: 30px;

                &.active {
                    color: #fff;
                    font-family: $robotoM;
                    background: $primaryColor;
                    border: 2px solid $primaryColor;
                }
            }
        }
    }

    .tab-content {
        .tab-pane {
            background: #E6E6E6;
    
            padding: 45px;
            

            .product-attributes {
                margin-bottom: 20px;

                .attributegroup-name {
                    color: $primaryColor;
                }
                .attribute-name {
                    width: 35%;
                }

                thead {
                    tr {
                        td {
                            border: 0px;
                            font-size: 1.1rem;
                        }
                    }
                }
            }
        }
    }
}
.related-title {
    font-size: 29px;
    font-family: $robotoM;
    color: #404040;
}
.panel {
    &.panel-default {
        border-bottom: 2px solid $primaryColor;
        padding: 25px 15px;

        .panel-default {
            border-bottom: 2px solid $primaryColor;
            padding: 25px 15px;
        }
    }
}
.br-container {
    width: 90%;
    margin: auto;
}

.divider {
    border-top: 3px solid $primaryColor;
}

ul.pagination {
    margin-top: 3rem;
    border-bottom: none;
    border-radius: 0;
    display: flex;
    justify-content: center;

    li {
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        margin-right:15px;

        a,
        span {
            font-size: 1.25rem;
            font-family: $robotoM;
            padding: 5px;
        }
    }
}

.options_row {
    margin-bottom: 20px;

    h2 {
        color: #7A7A7A;
        font-size: 23px;
        font-family: $robotoM;
    }

    .form-group {
        margin-bottom: 0;
        align-items: baseline;
    }

    label {
        margin-bottom: 0;
    }

    select {
        box-shadow: none;
        outline: none;
        background: transparent;
        border: 0px;
        border-radius: 0;
        margin-left: 5px;

        &:focus {
            box-shadow: none;
            outline: none;
        }
    }

    #list-view,
    #grid-view {
        border: 1px solid $primaryColor;

        &:focus {
            box-shadow: none;
        }

        &.active {
            i {
                color: $primaryColor;
            }
        }
    }

    #compare-total {
        color: $primaryColor;
        font-family: $robotoM;
        border: 1px solid;
        border-radius: 30px;

        &:hover {
            text-decoration: none;
            background: $primaryColor;
            color: #fff;
            border: 0;
        }
    }
}

.popover {
    width: 100%;
    border: 1px solid $primaryColor;
    border-radius: 30px;

    &.bs-popover-top {
        margin-bottom: 0.75rem;
    }

    .arrow {
        &:after {
            bottom: 0px;
            border-top-color: $primaryColor;
        }
    }

    .popover-body {
        padding: 1rem 0.75rem;

        h4 {
            font-size: 0.75rem;
            &.attribute-key {
                font-size: 0.8rem;
                font-family: $robotoM;
            }
        }
    }
}

.cc-window.cc-floating {
    border-radius: 30px;
}

/* GUIDES */

#guides {
    margin-bottom: 30px;

    .owl-item {
        img {
            max-width: 302px;
        }
    }

    .featured-title h4 {
        margin: 0px auto;
    }

    .odhgos {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .odhgos-wrap,
    .odhgos-overlay {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 20%;
        transition: 0.6s ease-in-out;
    }

    .odhgos-wrap {
        font-size: 0.95rem;
        font-family: $robotoM;
        color: #222;
        background: transparent;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
    }

    .odhgos-overlay {
        background: $primaryColor;
        opacity: 0.75;
    }

    .odhgoiborder {
        position: relative;
        overflow: hidden;
        border-radius: 30px;

        p {
            margin: 0px;
        }

        &:hover {
            border: 1px solid $primaryColor;
            .odhgos-wrap {
                height: 0;
                opacity: 0;
            }

            .odhgos-overlay {
                height: 0;
                opacity: 0;
            }
        }
    }
}

/* SCROLL TO TOP BUTTON */

#toTopContainer {
    // position: fixed;
    text-align: right;
    height: 0px;
    width: 96px;
    top: calc(100vh - 320px);
    right: 20px;

    &.sticky {
        top: calc(100vh - 120px);
        opacity: 1;
        position: fixed;
        height: 50px;
        z-index: 6;
        transition: all 0.3s ease;

        #scrollToTop {
            display: block;
        }
    }

    #scrollToTop {
        display: none;
        width: 50px;
        height: 50px;
        border: 0px;
        border-radius: 50px;
        -webkit-appearance: none;
        -moz-appearance: none;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);

        i {
            font-size: 1.5rem;
        }
    }
}

/* SHOPPING GUIDES */
.shopping-guide {
    &:nth-child(odd) {
        flex-direction: row-reverse;
    }
}
.guide-row {
    align-items: center;
    border: 1px solid $primaryColor;
    border-radius: 30px;
    margin: 2rem 0;
    padding: 2rem;

    .showcase-text {
        p {
            font-size: 1rem;
        }
    }
}

.live-search{
    ul{
        li{
            .product-name{
                color:#000;
                font-size:13px;
            }
            .product-price{
                .price{
                    color:#000;
                    font-size:13px;
                }
            }
        }
    }
} 

.common-home{
    .swiper-viewport{
        overflow:hidden!important;
    }
}

.swiper-viewport{
    box-shadow:none;
    border:none;
    border-radius:none;
}

div#productInfo {
    max-height: 300px;
    overflow: hidden;
}


.account-wishlist{
    table{
        img{
            max-width:100px;
        }
    }
}
#error-not-found{
    #content{
        text-align:center;
        .product-not-found{

            font-size:26px;
            color:#000;
            margin-top:45px;
             margin-bottom:45px;
        
    }
    }

}

.product-not-found{

    font-size:26px;
    color:#000;
    margin-top:45px;
     margin-bottom:45px;

}

.alert-success {
    position: fixed!important;
    width: 50vw!important;
    padding: 1.5rem 3rem;
    border-radius: 24px;
    background: #a8dfa8;
    left: 0;
    right: 0;
    margin: 0 auto;
    color:#000;
    z-index:99;
    top:20vh;
    font-family: $roboto;

    a{
        font-family: $roboto;
    }

    .close{
        border:none;
        background:#fff;
        border-radius:90px;
        top:10px;
        right:10px;
        position: absolute;
        width: 30px;
        height: 30px;
        font-size:30px;
        line-height: 1;
        font-weight:bold;


    }
}

.no-match{
    cursor: default!important;
    &:hover{
        background-color: unset!important;
    }
}


#product-search{
    .input-group>.form-control:focus, .input-group>.form-floating:focus-within, .input-group>.form-select:focus{
        z-index:1;
    }
}

.btn-wish{
    &:focus{
        border:none;
    }

}

@import "responsive";
