.header{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: $primaryColor;
    z-index: 2;
    .row--top {
        border-bottom: 1px solid rgba(255,255,255,.4);
    }
    .row--bottom {
        padding: 2em 7em;
        color: $white;
        border-bottom: 1px solid rgba(255,255,255,.4);   

        #menu-trigger {
            font-size: 1.8rem;
            cursor: pointer;
        } 
        .input-group {
            background-color: $secondaryColor;
            border-radius: 20px;
            border: 0;
            justify-content: space-between;
            max-width: 400px;
            padding: 3px 15px;

            input {
                background-color: $secondaryColor;
                border: 0;
                &:focus {
                    border: 0;
                    outline: 0;
                }
                &::-webkit-input-placeholder {
                    color: $white;
                }
                &::-moz-placeholder {
                    color: $white;
                }
                &:-ms-input-placeholder {
                    color: $white;
                }
                &:-moz-placeholder {
                    color: $white;
                }
            }
            .btn {
                border: 0;
                &:focus {
                    box-shadow: none;
                    outline: 0;
                }
                &:hover {
                    background-color: transparent;
                }
            }
            .fa {
                color: $white;
            }
        }
        .phones {
            img {
                background-color: $secondaryColor;
                border-radius: 20px;
                float: left;
                margin-top: 10px;
                margin-right: 10px;
                padding: 12px 15px;
            }
            p {
                margin-bottom: 0;
                margin-left: 3em;
            }
            a {
                font-size: 22px;
                font-family: $robotoM;
            }
        }
    }
    &__menu {
        align-self: center;
        &__list{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 15px;
            margin: 0;
            width: 100%;            
            &__item {
                min-width: 40px;
                height: 42px;
                text-align: center;
                color: #000;
                border: solid 2px $primaryColor;
                border-radius: 20px;                
                a,
                a:not([href]):not([tabindex]) {
                    color: #000;
                    font-size: 1rem;
                    font-weight: bold;
                    display: block;
                    padding: 8px 0px;
                }
                a:hover {
                    cursor: pointer;
                }
                .fa {
                    font-size: 1.25rem;
                }
                &.menu {
                    border-color: $primaryColor;
                    background-color: $primaryColor;
                    min-width: 20%;
                }
                &.search {
                    flex: 0 0 65%;

                    input {
                        width: calc(100% - 60px);
                        padding-left: 1.5rem;
                        border: 0;
                        outline: none;
                        background: transparent;
                    }
                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 60px;
                        height: 40px;
                        padding: 0;
                        background: transparent;
                    }
                }
            }
        }
        .dropdown-menu {
            background: #fff;
            border: 1px solid rgb(3, 0, 0);
            .dropdown-anchor {
                border-bottom-color: #000;
            }
            ul {
                box-shadow: none;
                li {
                    a {
                        &:hover {
                            background-color: $primaryColor;
                        }
                    }

                    p {
                        padding: 0 10px;
                        margin: 0;
                    }
                }
            }
        }
    }
    .product_counter {
        background-color: $thirdColor;
        height: 25px;
        width: 25px;
        color: $white;
        text-align: center;
        border-radius: 30px;
        position: absolute;
        top: -20px;
        font-size: 14px;
        font-family: $robotoM;
        right: 5px;
        display: flex;
        align-items:center;
        justify-content: center;
    }
}
#mobile-menu-trigger {
    position: relative;
    min-width: 20px;
    top: 7px;
    .menu.icon {
        position: relative;
    }
}
#nav-menu {
    position: absolute;
    background-color: $primaryColor;
    width: 100%;



    .nav-pills {
        padding: 1em 0;

        .nav-item {
            position: relative;
            .nav-link {
                position: relative;
                color: $white;
                font-family: $roboto;
                font-size: 18px;
                background-color: transparent;
                border-bottom: 1px solid rgba(255,255,255,.4);
                border-radius: 0;
                padding: 1rem 0.9rem;
                text-align: left;

                &.active, &:hover {
                    background-color: transparent;
                }
            }
        }
        .nav-item:last-child:after,
        .nav-item:first-child .nav-link:before {
            display: none;
        }
    }
    .tab-content {
  
        padding: 3em 2em 3em 5em;
    }

    ul {
        li {
            h4 {
                font-size: 18px;
                margin: 1em auto;
                a {
                    color: $white;
                }
            }
            h5 {
                font-size: 14px;
                font-family: $robotoL;
                a {
                    color: $white;
                    position: relative;
                }
                a:after {
                    content: "";
                    position: absolute;
                    width: 0;
                    left: 0;
                    height: 2px;
                    bottom: -1px;
                    transition: width 0.2s ease-out;
                }
                a:hover:after {
                    width: 100%;
                }
                a:hover {
                    color: $white;
                    transition: color 0.2s ease-out;
                }
            }
        }
    }
}

#dropdown-account{
    height: 100%;
    z-index:9999;
    background:#fff;
    
    &.login-aside {
        border-radius: 0;
        border: {
            left: 1px solid $primaryColor;
            top: 0;
            right: -1px;
            bottom: 1px solid $primaryColor;
        };
        transition: all .3s ease-out;
        transform: translateX(100%);

        &.show {
            transform: translateX(0);
            width: 24.53vw;
            max-width: unset;
        }

        @include media-breakpoint-between(xs, md) {
            &.dropdown-opened {
                transform: translateX(0);
                width: 90vw;
                max-width: 90vw;
            }
        }

        margin-top: 0;
        position: fixed !important;
        right: 0 !important;
        top:unset!important;
        left: unset !important;
        bottom: 0 !important;
    }

    .cart-header-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 7.94%;
        border-bottom: 2px solid black;
    }
}


.login-aside{
    h2{
        margin-bottom:15px;
        color:$orange;
        text-align:center;
        display: block;
        text-align: center;
        width:100%;
    }
    &__form{
        text-align:center;
        .login-top{
            margin-bottom:40px;
            .register-link{
                display:block;
                text-align:center;
                margin-bottom:15px;
                font-size:18px;
                font-family:$robotoM;
                color:#000
            }
            .register-btn{
                background:$primaryColor;
                color:#fff;
                font-size:18px;
                border-radius:0;
                border:1px solid $primaryColor;
                transition:all .4s;
                padding:10px 25px;
                border-radius:25px;
                &:hover{
                    background-color: #fff;
                    color:$primaryColor;
                }
            }
        }

        .already{
            display:block;
            text-align:center;
            margin-bottom:15px;
            font-size:18px;
            font-family:$robotoM;
            color:#000;
        }

        .submit{
            background:#000;
            color:#fff;
            font-size:18px;
            border-radius:0;
            border:1px solid #000;
            transition:all .4s;
            padding:10px 25px;
            transition:all .4s;
            border-radius:25px;
            &:hover{
                background-color: #fff;
                border:1px solid #000;
                color:#000;
            }
        }

        .form-group{
            margin-bottom:20px;
        }

        .form-control{
            border-radius:0;
            color:#1E6488;

            &::placeholder{
                color:#1E6488;
            }

        }

        .forgotten{
            display:block;
            text-align:center;
            margin-bottom:15px;
            margin-top:15px;
            font-size:18px;
            font-family:$robotoM;
            color:#000;  
        }
    }

    &__header{
        &.mobile{
            display: flex;
            align-items:center;
            margin-bottom:30px;
        }
    }
}



#dropdown-cart {
    z-index: 99;
    height: calc(100vh - 100px);

    .register-btn {
        color: #fff;
        &:hover {
            background-color: #fff;
            color: #000;
            border: 2px solid #000;
        }
    }
    
    &.cart-aside {
        margin-top: 0;
        position: fixed !important;
        right: 0;
        left: unset;
        bottom: 0 ;
        transition: all .33s ease-out;
        transform: translateX(100%);
        top:unset;
        height:100%;
        width: 24.53vw;

        border: {
            radius: 0;
            left: 1px solid $primaryColor;
            top: 0;
            right: 0;
            bottom: 1px solid $primaryColor;
        };        

        &.show {
            transform: translateX(0px);
            width: 24.53vw;
            max-width: unset;
            display:block!important;
        }

        @include media-breakpoint-between(xs, md) {
            &.dropdown-opened {
                transform: translateX(0);
                width: 90vw;
                max-width: 90vw;
            }
        }        
    }

    .cart-header-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 7.94%;
        border-bottom: 2px solid black;
    }
}

#dropdown-cart {
    padding-top:0;
    background:#fff;
    transform: translateZ(0);
  
    .closer{
        position:absolute;
        top:10px;
        left:10px;
        cursor: pointer;
        svg{
            width:30px;
        }
    }

    &>ul {
        padding: 0;
        &>li {
            line-height:20px;
        }
    }
    ul {
        box-shadow:none!important;
        li {
            max-height:65vh;
            overflow-y: auto;
            
            
                &::-webkit-scrollbar {
                    width:10px;
                }
                
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                }
                
                &::-webkit-scrollbar-thumb {
                background-color: $primaryColor;
                outline: 1px solid $primaryColor;
                border-radius:90px;
                }

            .cart_head_row {
                padding: 0;
                background:$primaryColor;
                td {

                    padding: 0;
                    background: $primaryColor;
                    border:none;
                    &.text-right {
                        padding: 0.3rem 1rem 0.3rem 0;
                    }
                    a {
                        color: white;
                    }
                    i{
                        margin-right:10px;
                    }
                }
            }
            .cart_product_row {
                td {
                    position: relative;
                    a {
                        display: block;
                        font-family: $robotoB;
                        font-size:16px;
                        color:#000;
                    }
                    .cart-moder{
                        position:unset;
                        font-size:13px;
                        font-family:$roboto;
                        margin-bottom:0;
                    }
                    .cart_product_model {
                        display: block;
                        font-family: $robotoM;
                    }
                    .cart_product_info {
                        display: flex;
                        font-family: $roboto;
                        flex-wrap:wrap;
                        color:$orange;
                        font-size:18px;
                        justify-content:space-between;
                        align-items:center;
                        
                        .cart-qty{
                            flex:0 0 auto;
                            width:50%;







                            }

                        


                        .quantity-cart-table{
                            font-size:14px;
                            color:#000;
                        }

                    }
                    .btn-remove {

                    }

                    &:last-child {
                       
                    }
                }
            }
            p {
                padding: 0;
                margin-top:20px;
                bottom: 0;
                width: 100%;
                color:#000;

                a {
                    width: 100%;
                    display: block;
                    background: black;
                    color: $white;
                    text-align: center;
                    cursor: pointer;
                    line-height: 50px;
                }
            }
            &.empty_cart {
                display:flex;
                align-items:center;
                padding-top:20px;

                .btn{
                    position:relative;
                    z-index:9;
                }

                p {
                    padding: 0;
                    position: absolute;
                    top: 50px;
                    width: 100%;
                    margin-bottom:0;
                }
                @include media-breakpoint-between(xs, md) {
     

                    p {
                        position: static;
                        top: unset;
                        width: 100%;
                    }
                }
            }
        }
    }

    .cart-totals{
        width:100%;
        bottom:0;
     
        background:#fff;

        .cart-totals-table{
            margin-top:15px;
            margin-bottom:8rem;
            .text-right{
                text-align:right;
                font-weight:bold;
                font-size:18px;
            }
        }
    }

    .cart-checkout{
        padding-left:2rem;
        padding-right:2rem;
    }

    .checkout-cart{
        border-radius:30px;
        background:$primaryColor;
        color:#fff;
        margin-bottom:0;
 
    }
}

.cart_product_row{
    display:flex;
    .cart-image{
        flex:0 0 auto;
        width:15%;
    }
    .product-list{
        flex:0 0 auto;
        width:50%;
        padding-top: 15px;
    }
    .cart-quantity{
        flex:0 0 auto;
        width:10%;
        padding-top:15px;
    }
    .cart-total{
        flex:0 0 auto;
        width:15%;
        padding-top:15px;
    }

    .cart-remove{
        flex:0 0 auto;
        width:10%;       
    }
}

#cartTrigger{
    position:relative;
}

.search-open{
    color:#fff;
    border:none;
    background:transparent;
}

.hc-offcanvas-nav{
    .nav-item{
        background:#2771e0;
    }
}

.header-info-mobile{
    ul{
        display:flex;
        list-style: none;
        margin-bottom:0;
    }
}

.minicart-no-products{
    svg{
        filter:invert(1);
    }
}

.menu-tabs{
    max-height:30vw;
    overflow-y:scroll;
    li{
        flex:0 0 auto!important;
        width:100%;
    }
}

#v-pills-tabContent{
    max-height:30vw;
    overflow-y:scroll;
}


.menu-tabs,#v-pills-tabContent{
    &::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }
    
    
    &::-webkit-scrollbar
    {
        width: 12px;
        background-color: #F5F5F5;
        border-radius: 10px;
    }
    
    
    &::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #2771e0;
    }
}


.menu-burger{
    width: 25px;
    height: 25px;
    position: relative;
    top:2px;
  
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #fff;
      
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2) {
            top: 9px;
        }

        &:nth-child(3) {
            top: 18px;
        }
        
      }


      &.open{
        span{
            &:nth-child(1) {
                top: 11px;
                -webkit-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                -o-transform: rotate(135deg);
                transform: rotate(135deg);
              }
              &:nth-child(2) {
                opacity: 0;
                left: -60px;
              }
              &:nth-child(3) {
                top: 11px;
                -webkit-transform: rotate(-135deg);
                -moz-transform: rotate(-135deg);
                -o-transform: rotate(-135deg);
                transform: rotate(-135deg);
              }
        }
      }


  }
