$fa-font-path: "../webfonts";
$fa-font-family: 'Font Awesome 5 Free';

$theme-colors: (
    "primary": #2771E0,
    "secondary": #508CE6,
    "third": #D97400
);

$primaryColor: #2771E0;
$secondaryColor: #508CE6;
$thirdColor: #D97400;
$white: #fff;
$black: #000;

$roboto: 'Roboto';
$robotoB: 'Roboto-Bold';
$robotoM: 'Roboto-Medium';
$robotoL: 'Roboto-Light';

%smooth-hover {
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

%content {
	position: absolute;
	content: '';
}