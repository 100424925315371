#checkout-cart {
	.input-group {
		.form-control[name^="quantity"] {
			border: 1px solid $primaryColor;
			border-radius: 6px;
		}
	}
	.btn-update {
		margin-left: 5px;
		margin-right: 2px;
		height: 38px;
	}
	.btn-primary {
		min-height: 38px;
	}
	input[type="text"],
	select {
		border-radius: 6px;
		border: 1px solid $primaryColor;
    }    
    .buttons {
        display: flex;
        justify-content: space-between;
    }    
    .cart {
        margin-bottom: 2.5rem;
    }
    .cart__product {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 1rem 0 0.5rem;
        border-top: 1px solid #dde2e6;
        border-bottom: 1px solid rgba(221, 226, 230, 0.5);
        padding: 10px 0px;
    
        &__image {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
    
        &__details {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 75%;
            flex: 0 0 75%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
    
            &__info {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
    
                &__main {
                    margin-bottom: 0.75rem;
                }
    
                &__price {
                    font-size: 1rem;
                    font-weight:bold;
                }
    
                &__name {
                    color: #000;
                    font-size: 1rem;
                }
    
                &__span {
                    font-size: 0.65rem;
                }
            }
    
            &__actions {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
    
                &__input {
                    width: 80px;
                }
    
                button {
                    margin: 0px 0.5rem;
                }
            }
        }
    } 
}
#checkout-checkout {
    line-height: 1.8;
}

#checkout-cart{
    .table-responsive,.cart__product{
        img{
            width:100px;
        }
    }
}