.module {   
    margin-top: 9rem;
    
    &__heading {
        margin-bottom: 1.5rem;
        margin-top: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
            display: inline-flex;
            margin: 0;
            align-self: center;
        }

        &__wrapper {
            position: absolute;
            top: -6rem;
            display: inline-flex;
            align-items: center;
            background: $primaryColor;
            border-radius: 15px;
            padding: 1rem 2rem;

            &.bottom {
                top: -3rem;
            }
        }
    }

    &__body {
        padding-bottom: 3rem;
        margin-bottom: 3rem;

        .product-layout {
            margin-top: 1.5rem;

            .product-thumb {
                overflow: hidden;
                background: #fff;
                border: 0px;
                position: relative;
                border: 2px solid $primaryColor;
                border-radius: 30px;
        
                .free-shipping {
                    position: absolute;
                    width: 100px;
                    right: 5px;
                    top: 5px;
                    z-index: 2;
                }
        
                .product-model {
                    margin-bottom: 5px;
                }
        
                .image {
                    padding: 15px;
                }
        
                .caption {
                    min-height: unset;
                    padding: 20px 30px 0px;
        
                    h4 {
                        min-height: 54px;
        
                        a {
                            color: #444;
        
                            &:hover {
                                color: $primaryColor;
                            }
                        }
                    }
        
                    .price {
                        color: $primaryColor;
                        font-size: 1.25rem;
                        font-family: $robotoB;

                        .price-old {
                            font-size: 1rem;
                            color: #999;
                            text-decoration: line-through;
                            margin-left: 10px;
                        }
                    }
                }
        
                .button-group {
                    display: flex;
                    background: #fff;
                    overflow: hidden;
        
                    button {
                        width: 25%;
                        background-color: #fff;
                        border: 0px;
                        outline: none;
                        line-height: 38px;
                        font-family: $robotoB;
        
                        &:not(.product-info) {
                            &:hover {
                                background: $primaryColor;
                                img {
                                    transform: scale(1.1);
                                }
                            }
                        }
        
                        img {
                            width: 20px;
                            transform: scale(1);
                        }
                    }
                }
            }
        
            &.product-list {
                width: 100%;
        
                .product-thumb {
                    .details {
                        margin-left: 258px;
                        border-left: 2px solid $primaryColor;
        
                        .caption {
                            min-height: 200px;
                        }
                    }
        
                    .button-group {
                        border-left: 0px;
                    }

                    .free-shipping {
                        right: 5px;
                        left: unset;
                    }
                }
            }
        }
    }

    #featured-tab {
		border-bottom: none;

		.nav-item {
			position: relative;
			&:first-child {
				.nav-link {
					border-left: 3px solid $primaryColor;
					border-radius: 30px;

					&.active {
						border-left: 3px solid $primaryColor;
						border-radius: 30px;
					}
				}
			}
			&:last-child {
				.nav-link {
					border-right: 3px solid $primaryColor;
					border-radius: 30px;
				}
			}
			.nav-link {
				font-family: $robotoB;
				font-size: 0.9rem;
				position: relative;
			}
			.nav-link.active {
				color: #333;
				border-color: transparent;
				background-color: transparent;
			}
		}
		.nav-item:hover {
			.nav-link {
				color: #333;
			}
			.nav-link:focus,
			.nav-link:hover {
				border-color: transparent;
			}
		}
		.nav-item:first-child {
			.nav-link:before {
				content: "";
				position: absolute;
				top: 2px;
				left: 6px;
				width: 70px;
				height: 2px;
				background-color: $primaryColor;
			}
			.nav-link:after {
				content: "";
				position: absolute;
				bottom: 2px;
				left: 6px;
				width: 70px;
				height: 2px;
				background-color: $primaryColor;
			}
		}
		.nav-item:last-child {
			.nav-link:before {
				content: "";
				position: absolute;
				top: 2px;
				right: 6px;
				width: 70px;
				height: 2px;
				background-color: $primaryColor;
			}
			.nav-link:after {
				content: "";
				position: absolute;
				bottom: 2px;
				right: 6px;
				width: 70px;
				height: 2px;
				background-color: $primaryColor;
			}
		}
		.nav-item:not(:last-child):after {
			content: "";
			position: absolute;
			bottom: calc(50% - 2px);
			right: -3px;
			width: 6px;
			height: 6px;
			border-radius: 100%;
		}
    }
    
    .hot-area-label {
		background-color: #f26521;
		padding: 15px 30px;
		border-radius: 30px;
		h3 {
			color: #fff;
			margin-right: 30px;
			font-family: $robotoB;
		}
	}
	.grey-border-thick {
		height: 5px;
    }
    .hot-area {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        max-height: 422px;
        border-radius: 30px;
    }
    
	.special-carousel-row {
		padding: 4rem 0;
        
        #special-carousel {
            padding-left: 15px;
            .owl-item {
                .product-layout {
                    margin: 0;
                    .product-thumb {
                        .free-shipping {
                            position: absolute;
                            width: 100px;
                            right: 5px;
                            top: 5px;
                            z-index: 2;
                        }
                        .image {
                            img {
                                width: unset;
                            }
                        }
                    }
                }
            }
        
            .owl-dots {
                text-align: center;
                margin-top: 2rem;
        
                .owl-dot {
                    width: 15px;
                    height: 15px;
                    border: 1px solid $primaryColor;
                    background: #fff;
                    border-radius: 15px;
                    margin: 0px 5px;
                    outline: none;
        
                    &.active {
                        background: $primaryColor;
                    }
                }
            }
        }
	}
}
.main-swiper {
    .swiper-wrapper {
        .swiper-slide {
            img {
                transform: scale(1);
                transition: .3s ease-in-out;
            }
            .caption {
                position: absolute;
                font-size: 40px;
                color: $white;
                left: 50%;
                top: 50%;
                .btn-default {
                    background-color: $thirdColor;
                    font-size: 17px;
                    border-radius: 20px;
                    padding: 7px 22px;
                    color: $white;
                    margin-top: 2em;
                    border: 0;
                }
            }
            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
        
    .swiper-pagination {
        bottom: 1em;
        .swiper-pagination-bullet {
            width: 60px;
            height: 4px;
            background-color: $white;
            border-radius: 0;
            &.swiper-pagination-bullet-active {
                background-color: $thirdColor;
            }
        }
    }
}
.container-custom {
    padding: 2em 9em;
}
.featured--wrap, .home--tabs {
    padding: 4em 10em ;
    .maintitle {
        color: #404040;
        font-size: 29px;
    }
    .nav-link {
        padding: 8px 30px;
        color: #2771E0;
        font-size: 17px;
        font-family: $robotoB;
        background-color: transparent;
        border: 2px solid #2771E0;
        border-radius: 30px;
        &.active {
            background-color: #2771E0;
            color: $white;
        }
    }
}

.info-wrapper{
    padding:0;
    .video-container{
        width:100%;
        display:flex;
       
        justify-content: center;
        iframe{
            margin-top:-120px;
        }
    }

    #tab-description{
        margin-top:190px;
    }

    #tab-specification{
        margin-top:50px;
    }





}

.info-container{
    margin-top:30px;
    text-align:center;
}
.tabs_row {
    
    .maintitle {
        color: #404040;
        font-size: 29px;
    }
    .nav-link {
        padding: 8px 30px;
        color: #2771E0;
        font-size: 17px;
        font-family: $robotoB;
        background-color: transparent;
        border: 2px solid #2771E0;
        border-radius: 30px;
        &.active {
            background-color: #2771E0;
            color: $white;
        }
    }
}
#slideshow0, .slider0 {
    display: block;
}
#slideshow1, .slider1 {
    display: none;
}
#product-search {
    .options_row--1 {
        .input-group {

            .form-control {
                margin-bottom: 0;
                background-color: #508ce6;
                border-radius: 20px;
                color: #fff;
            }
            .input-group-text {
                margin-left: -40px;
                border-radius: 20px;
            }
        }
    }
}
.product-layout {
    .product-thumb {
        padding: 10px;
        border: 1px solid #f0f0f0;
        border-color: transparent;
        .image {
            img {
                transform: scale(1);
                transition: .3s ease-in-out;
            }
            &:hover {
                img {
                    transform: scale(1.2);
                }
            }
        }
        .details {
            .price {
                .price {
                    font-size: 29px;
                    font-family: $robotoB;
                    color: #404040;
                }
                .price-old {
                    color: #404040;
                    font-family: $robotoM;
                    font-size: 20px;
                    text-decoration: line-through #D97400;
                    margin-right: 1rem;
                }
                .price-new {
                    color: #404040;
                    font-family: $robotoB;
                    font-size: 29px;
                }                
            }
            .caption {
                h4 {
                    font-family: $robotoM;
                    font-size: 18px;
                    overflow: hidden;
                    min-height: 65px;
                    max-height: 65px;
                    a {
                        color: #404040;
                    }
                }
            }
        }
    }
}
.button-group {
    .btn-cart {
        color: #2771E0;
        border: 1px solid #2771E0;
        border-radius: 20px;
        font-size: 17px;
        font-family: $robotoB;
        padding: 8px 30px;
    }
    .btn-wish {
        .far {
            border: 1px solid #D97400;
            border-radius: 100%;
            color: #D97400;
            padding: 8px;
            font-size: 24px;
        }
    }
}
.swiper-carousel {
    .swiper-slide {
        img {
            
            /* transform: scale(1.1); */
            transition: .3s ease-in-out;
        }
        &:hover {
            img {
                filter: brightness(55%);
                transform: scale(1.1);
            }
        }
    }    
    .caption {
        position: absolute;
        top: 58%;
        left: 10%;
        color: #fff;
        p {
            font-size: 31px;
        }
        span {
            font-size: 20px;
        }
        .link {   
            text-align: right;
            margin-top: 1em;
        }
    }
}

.category {
    .panel-title {
        font-size: 20px;
        line-height: 23px;
        padding-top: 2px;
        font-weight: 700;
        text-transform: uppercase;
        color: #252525;
    }
    .panel-default>.panel-heading {
        padding: 10px 15px;
        border-bottom: none;
    }
    .list-group-item:last-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .panel-body {
        padding: 0;
    }
}

#featuredTabContent{
    position:relative;
    .owl-stage{
        width:100%;
        display:flex;
    }
    .owl-stage-outer{
        overflow:hidden;
    }

    .owl-prev,.owl-next{
        position:absolute;
        border:none;
        background:transparent;

        span{
            color:#2771e0;
            font-size:60px;
        }
    }

    .owl-prev{
        top:50%;
        left:-2%;

    }

    .owl-next{
        top:50%;
        right:-2%;
    }

}

.latest-carousel,.bestseller-carousel,.special-carousel,.related-carousel{
    position:relative;
    .owl-stage{
        width:100%;
        display:flex;
    }
    .owl-stage-outer{
        overflow:hidden;
    }

    .owl-prev,.owl-next{
        position:absolute;
        border:none;
        background:transparent;

        span{
            color:#2771e0;
            font-size:60px;
        }
    }

    .owl-prev{
        top:50%;
        left:-2%;

    }

    .owl-next{
        top:50%;
        right:-2%;
    }

}

.related-carousel{
    .owl-prev{
        top:50%;
        left:6%;

    }

    .owl-next{
        top:50%;
        right:6%;
    }
}

.accordion-button{
    padding-left:0;
    &:focus{
        background:transparent;
    }
    &:not(.collapsed){
        background:transparent;
        color:#000;
        box-shadow:none;
    }
}

.product-container{
    padding:2em 9em;
}

#product-product{
    padding-top:2em;
}

.product-thumb{
    .image{
        position:relative;
        .product-labels{
            position:absolute;
            top:0;
            left:0;
            display:flex;

            .new-product{
                border-radius:90px;
                background:#EBEBEB;
                width:50px;
                height:50px;
                display: flex;
                justify-content: center;
                align-items:center;

                margin-left:10px;
    
                p{
                    color:#2771E0;
                    font-size:20px;
                    padding:20px;
                    margin-bottom:0;
                }
            }
            .offer{
                border-radius:90px;
                background:#1A8528;
                width:50px;
                height:50px;
                display: flex;
                justify-content: center;
                align-items:center;

                margin-left:10px;
    
                p{
                    color:#fff;
                    font-size:20px;
                    padding:20px;
                    margin-bottom:0;
                }
            }

        }

    }
}

#tab-specification{
    .product-attributes{
        td{
            background:transparent;
        }
    }
}

.added{
    background:#d97400;
    color:#fff!important;
}

#product{
    .accordion-body{
        padding: 16px 20px 16px 0;
    }
}

.live-search ul li{
    color:#000;
}



.fpa{
    color:#000;
    font-size:13px;
    font-weight:bold;
    margin-left:10px;
}

.accordion-body{
    .attribute-name{
        width:50%;
    }
}

.thumbslider{
    position:relative;
    .owl-next{
        font-size:50px!important;
        color:#2771e0!important;
        position:absolute;
        top:25%;
        right:5px;
    }

    .owl-prev{
        font-size:50px!important;
        color:#2771e0!important;
        position:absolute;
        top:25%;
        left:5px;
    }
}

#carousel-guides{
    
        .caption{
            position: absolute;
            top: 58%;
            left: -12%;
            color: #fff;
            width: 100%;

            .link{
                margin-top:4em;
            }
        }
    
}